import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Head from "../components/Head"
import Footer from "../components/Footer"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="StreamKit Blog" />
      <Head />
      <div className="w-100 h5 flex flex-column items-center justify-center tc" style={{ backgroundImage: "linear-gradient(to right, #9932CC, #9111ff)" }}>
        <div className="b f1 white mb2">StreamKit Blog</div>
        <div className="f4 light-gray white lh-copy" style={{ maxWidth: "35rem" }}>
          Learn how to promote, grow, and monitor your Twitch channel to maximize growth and engagement.
        </div>
      </div>
      <div className="flex-l flex-wrap db w-100 pa3 justify-between" style={{ listStyle: `none` }} style={{ gap: "25px 0" }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const image = getImage(post.frontmatter.hero_image)

          return (
            <div key={post.fields.slug} className="mb4 mb0-l br3 b--light-gray ba shadow-hover" style={{ flexBasis: "49%" }}>
              <article
                className="post-list-item w-100"
                itemScope
                itemType="http://schema.org/Article"
              >
                <Link to={post.fields.slug} className="no-underline" itemProp="url">
                  <div className="w-100">
                    <div className="br--top br3 w-100 overflow-hidden h5 flex items-center">
                      <GatsbyImage
                        image={image}
                        alt={post.frontmatter.hero_image_alt}
                        width="100%"
                        layout="fluid"
                      />
                    </div>
                    <div className="pa3">
                      <div className="f4 mid-gray mb3">{post.frontmatter.date}</div>
                      <header>
                        <h2 className="mt0 mb2">
                          <span className="dark-gray f3" itemProp="headline">{title}</span>
                        </h2>
                      </header>
                      <section>
                        <div
                          className="mid-gray lh-copy"
                          dangerouslySetInnerHTML={{
                            __html: post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                        />
                      </section>
                    </div>
                  </div>
                </Link>
              </article>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
