import React from "react"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { StaticImage } from "gatsby-plugin-image"

import { discordUrl, appstoreUrl } from "../constants"

const Head = ({ showBlog, showClipDownloader, white }) => (
  <div className="w-100 flex flex-row pa2 items-center">
    <a rel="noreferrer" href="/" className="no-underline mr4">
      <div className="flex flex-row items-center">
        <StaticImage
          imgClassName="br2"
          backgroundColor="transparent"
          placeholder="none"
          height={35}
          width={35}
          src="../images/180.png"
          alt="icon"
          quality={100}
        />
        <div className={`db-l dn f3 ${white ? 'white' : 'background-color'} ml1`}>Stream</div>
        <div className={`db-l dn f3 ${white ? 'white' : 'background-color'} b`}>Kit</div>
      </div>
    </a>
    <div className="ml-auto items-center flex flex-row" style={{ gap: "0 15px" }}>
      {showBlog && (
        <a href="/blog" className={`no-underline b pointer dim ${white ? 'white' : 'dark-gray'} mr3`}>Blog</a>
      )}
      <a rel="noreferrer" href={discordUrl} target="_blank" className={`no-underline b pointer dim ${white ? 'white' : 'dark-gray'} mr3`}>Discord</a>
      <OutboundLink href={appstoreUrl} target="_blank" className="no-underline ml-auto-l ml0" rel="noreferrer">
        <div className={`grow pointer ${white ? 'bg-white' : 'bg-primary'} br3 pa2 flex flex-row items-center cta mb0-l`}>
          {white ? (
            <StaticImage
              backgroundColor="transparent"
              placeholder="none"
              height={20}
              width={20}
              src="../images/apple-logo-black.png"
              alt="app store"
              quality={100}
            />
          ) : (
            <StaticImage
              backgroundColor="transparent"
              placeholder="none"
              height={20}
              width={20}
              src="../images/apple-logo.png"
              alt="app store"
              quality={100}
            />
          )}
          <div className={`${white ? 'near-black' : 'white'} b f5 ph2`}>Download <span className="dn dib-l">StreamKit</span></div>
        </div>
      </OutboundLink>
    </div>
  </div>
)

Head.propTypes = {
  showBlog: PropTypes.bool,
  showClipDownloader: PropTypes.bool,
  white: PropTypes.bool,
}

Head.defaultProps = {
  showBlog: false,
  showClipDownloader: false,
  white: false,
}

export default Head
